import { ReactNode } from 'react';
import {
  Grid,
  Typography,
  WidgetContainer,
  Label,
  useTranslations,
} from '@uniqkey-frontend/shared-app';

interface ISCIMIntegrationWidget {
  title: string;
  description: string;
  isSCIM: boolean;
  children: ReactNode;
}

const SCIMIntegrationWidget = (props: ISCIMIntegrationWidget) => {
  const {
    title, description, isSCIM, children,
  } = props;
  const { t } = useTranslations();

  return (
    <WidgetContainer container withShadow p={3}>
      <Grid item xs={8.5}>
        <Grid item>
          <Typography variant="subtitle1" mb={2}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" mb={2}>{description}</Typography>
        </Grid>
        <Grid container item rowGap={3}>
          {children}
        </Grid>
      </Grid>
      <Grid item xs={3.5} textAlign="end">
        {isSCIM && <Label text={t('settingsPage.scimIntegrationTab.scimActive')} />}
      </Grid>
    </WidgetContainer>
  );
};

export default SCIMIntegrationWidget;
