import { Suspense } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import '../helpers/ChartJSLib/config';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './Router';
import UserProviderContext from './contexts/UserContext/provider';
import APIClientsProviderContext from './contexts/APIClientsContext/provider';

const ReactQueryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={ReactQueryClient}>
    <UserProviderContext>
      <APIClientsProviderContext>
        <Suspense fallback={null}>
          <Router />
        </Suspense>
        <ReactQueryDevtools />
      </APIClientsProviderContext>
    </UserProviderContext>
  </QueryClientProvider>
);

export default App;
