import { useState, useCallback, useMemo } from 'react';
import {
  Grid,
  Divider,
  usePubSub,
  PanelContent,
  SearchableTextField,
  useTranslations,
  FilterButton,
  Tooltip,
  usePopper,
  useMapKeyValueExtractor,
  ActionButton,
  ShareIcon,
  ErrorIcon,
  DeleteIcon,
  useSnackbar,
  PromptModal,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  encryptSymmetric,
  Box,
  Button,
  PlusIcon,
} from '@uniqkey-frontend/shared-app';
import { Ownership } from '@uniqkey-backend-organization-web/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import useOrganizationCreditCardsTable, {
  IOrganizationCreditCardsTableRow,
} from '../../../../hooks/tables/useOrganizationCreditCardsTable';
import OrganizationCreditCardsTable
  from '../../../../components/tables/OrganizationCreditCardsTable';
import OrganizationCreditCardsTabFilter, {
  IOrganizationCreditCardsTabFilterSubmitResult,
} from './components/OrganizationCreditCardsTabFilter';
import { TSelectionOptionType } from '../../../../components/SelectionModalOption';
import { useDeleteVaults, useDetachVaults } from '../../../../hooks/reactQuery';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import ShareVaultsToEmployeeModal from '../../../../components/ShareVaultsToEmployeeModal';
import ShareVaultsToEmployeeOrGroupSelectionModal
  from '../../../../components/ShareVaultsToEmployeeOrGroupSelectionModal';
import { logException } from '../../../../services/sentryService';
import CreateCreditCardModal, {
  ICreateCreditCardReturnValue,
} from '../../../../components/CreateCreditCardModal';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import useVaultsAPI from '../../../../hooks/useVaultsAPI';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';

const OrganizationCreditCardsTab = () => {
  const [isShareCreditCardsModalOpen, setIsShareCreditCardsModalOpen] = useState<boolean>(false);
  const [shareCreditCardsModalType, setShareCreditCardsModalType] = useState<
    TSelectionOptionType | undefined
  >(undefined);
  const [isDetachCreditCardsModalOpen, setIsDetachCreditCardsModalOpen] = useState<boolean>(false);
  const [isDeleteCreditCardsModalOpen, setIsDeleteCreditCardsModalOpen] = useState<boolean>(false);
  const [isCreateCreditCardModalOpen, setIsCreateCreditCardModalOpen] = useState<boolean>(false);
  const [isCreateCreditCardLoading, setIsCreateCreditCardLoading] = useState<boolean>(false);

  const { t } = useTranslations();
  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const { showError, showSuccess, showWarning } = useSnackbar();
  const { symmetricKey } = useCompanionApplicationContext();
  const { createVaultCreditCard } = useVaultsAPI();

  const {
    selectedCreditCards,
    refetch,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useOrganizationCreditCardsTable({
    noDataMessageKey: 'organizationCreditCardsTab.noData',
  });

  const {
    values: selectedCreditCardsAsObjects, keys: selectedCreditCardsIds,
  } = useMapKeyValueExtractor<IOrganizationCreditCardsTableRow>(selectedCreditCards);
  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;
  const {
    mutate: mutateDetachCreditCard, isLoading: isDetachCreditCardsLoading,
  } = useDetachVaults();
  const {
    mutate: mutateDeleteCreditCard, isLoading: isDeleteCreditCardsLoading,
  } = useDeleteVaults();

  const handleRefetch = useCallback(() => refetch(true), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_VAULT, handleRefetch);

  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(
    () => setIsFilterOpen(false),
    [setIsFilterOpen],
  );
  const handleDetachCreditCardsModalOpen = useCallback(
    () => setIsDetachCreditCardsModalOpen(true),
    [],
  );
  const handleDetachCreditCardsModalClose = useCallback(
    () => setIsDetachCreditCardsModalOpen(false),
    [],
  );
  const handleDeleteCreditCardsModalOpen = useCallback(
    () => setIsDeleteCreditCardsModalOpen(true),
    [],
  );
  const handleDeleteCreditCardsModalClose = useCallback(
    () => setIsDeleteCreditCardsModalOpen(false),
    [],
  );
  const handleShareCreditCardsModalClose = useCallback(
    () => setIsShareCreditCardsModalOpen(false),
    [],
  );
  const handleCreateCreditCardModalOpen = useCallback(
    () => setIsCreateCreditCardModalOpen(true),
    [],
  );
  const handleCreateCreditCardModalClose = useCallback(
    () => setIsCreateCreditCardModalOpen(false),
    [],
  );
  const handleShareCreditCardsModalTypeChange = useCallback((type: TSelectionOptionType) => {
    setShareCreditCardsModalType(type);
    handleShareCreditCardsModalClose();
  }, [handleShareCreditCardsModalClose]);
  const handleShareCreditCardsModalTypeClose = useCallback(
    () => setShareCreditCardsModalType(undefined),
    [],
  );
  const handleShareCreditCardsModalOpen = useCallback(
    () => (selectedCreditCardsAsObjects[0].ownership === Ownership.Groups
      ? handleShareCreditCardsModalTypeChange(Ownership.Groups)
      : setIsShareCreditCardsModalOpen(true)),
    [selectedCreditCardsAsObjects, handleShareCreditCardsModalTypeChange],
  );

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((
    updatedValues: IOrganizationCreditCardsTabFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const handleCreateCreditCard = useCallback(async (params: ICreateCreditCardReturnValue) => {
    const {
      name, cardNumber, expiryDate, cvv, cardHolderName,
    } = params;
    setIsCreateCreditCardLoading(true);
    try {
      await createVaultCreditCard({
        name,
        cardNumber: encryptSymmetric({
          key: symmetricKey,
          string: cardNumber,
        }),
        expiryDate: encryptSymmetric({
          key: symmetricKey,
          string: expiryDate,
          fallbackValue: '',
        }),
        cardHolderName: encryptSymmetric({
          key: symmetricKey,
          string: cardHolderName,
          fallbackValue: '',
        }),
        cvv: encryptSymmetric({
          key: symmetricKey,
          string: cvv,
          fallbackValue: '',
        }),
      });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.creditCard.created'),
        });
      } else {
        showWarning({
          text: t('createCreditCardModal.approvalOnMobileToast'),
        });
      }
      handleCreateCreditCardModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'OrganizationCreditCardsTab/handleCreateCreditCard exception',
      });
    } finally {
      setIsCreateCreditCardLoading(false);
    }
  }, [
    createVaultCreditCard,
    handleCreateCreditCardModalClose,
    symmetricKey,
    showError,
    showSuccess,
    showWarning,
    isTrustedPortalEnabled,
    t,
  ]);

  const handleDetachCreditCard = useCallback(async () => {
    mutateDetachCreditCard({
      vaultIds: selectedCreditCardsIds,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t(
              'organizationCreditCardsTab.detachCreditCardModal.successMessage',
              { count: successCount },
            ),
          });
        }
        if (failCount) {
          showError({
            text: t('organizationCreditCardsTab.detachCreditCardModal.errorMessage', {
              count: failCount,
            }),
          });
        }
        handleDetachCreditCardsModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t('common.somethingWentWrong') });
        logException(e, {
          message: 'OrganizationCreditCardsTab/handleDetachCreditCard exception',
        });
      },
    });
  }, [
    selectedCreditCardsIds,
    mutateDetachCreditCard,
    handleDetachCreditCardsModalClose,
    resetSelectedRows,
    resetActivePage,
    showSuccess,
    showError,
    t,
  ]);

  const handleDeleteCreditCard = useCallback(async () => {
    mutateDeleteCreditCard({
      vaultIds: selectedCreditCardsIds,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t(
              'organizationCreditCardsTab.deleteCreditCardModal.successMessage',
              { count: successCount },
            ),
          });
        }
        if (failCount) {
          showError({
            text: t(
              'organizationCreditCardsTab.deleteCreditCardModal.errorMessage',
              { count: failCount },
            ),
          });
        }
        handleDeleteCreditCardsModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t('common.somethingWentWrong') });
        logException(e, {
          message: 'OrganizationCreditCardsTab/handleDeleteCreditCard exception',
        });
      },
    });
  }, [
    selectedCreditCardsIds,
    mutateDeleteCreditCard,
    handleDeleteCreditCardsModalClose,
    resetSelectedRows,
    resetActivePage,
    showSuccess,
    showError,
    t,
  ]);

  const {
    isShareDisabled,
    isRemoveDisabled,
    isDeleteDisabled,
    shareTooltipTitle,
    removeTooltipTitle,
    deleteTooltipTitle,
  } = useMemo(() => {
    if (!selectedCreditCardsAsObjects.length) {
      return {
        isShareDisabled: true,
        isRemoveDisabled: true,
        isDeleteDisabled: true,
        shareTooltipTitle: t('common.tickRow'),
        removeTooltipTitle: t('common.tickRow'),
        deleteTooltipTitle: t('common.tickRow'),
      };
    }
    let disableShare = false;
    let disableRemove = false;
    let disableDelete = false;
    let shareTitle = t('organizationCreditCardsTab.share');
    let removeTitle = t('organizationCreditCardsTab.remove');
    let deleteTitle = t('organizationCreditCardsTab.delete');
    selectedCreditCardsAsObjects.forEach(({ ownership }) => {
      if (ownership !== selectedCreditCardsAsObjects[0].ownership) {
        disableShare = true;
        disableRemove = true;
        disableDelete = true;
        shareTitle = t('common.valuesInManagedByShouldBeIdentical');
        removeTitle = t('common.valuesInManagedByShouldBeIdentical');
        deleteTitle = t('common.valuesInManagedByShouldBeIdentical');
      }
      if (ownership === Ownership.Unmanaged) {
        disableRemove = true;
      }
      if (ownership === Ownership.Employees) {
        disableShare = true;
        disableDelete = true;
      }
      if (ownership === Ownership.Groups) disableDelete = true;
    });
    return {
      isShareDisabled: disableShare,
      isRemoveDisabled: disableRemove,
      isDeleteDisabled: disableDelete,
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
      deleteTooltipTitle: deleteTitle,
    };
  }, [selectedCreditCardsAsObjects, t]);

  const ownership = useMemo(() => {
    if (selectedCreditCardsAsObjects.length) {
      return selectedCreditCardsAsObjects[0].ownership;
    }
    return null;
  }, [selectedCreditCardsAsObjects]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareCreditCardsModalOpen}
                disabled={isShareDisabled}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDetachCreditCardsModalOpen}
                disabled={isRemoveDisabled}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={deleteTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteCreditCardsModalOpen}
                disabled={isDeleteDisabled}
              >
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          <Box mr={3} />
          <Grid item>
            <Button
              icon={<PlusIcon />}
              onClick={handleCreateCreditCardModalOpen}
            >
              {t('creditCardsTab.createCreditCardButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <OrganizationCreditCardsTable
        selectedCreditCards={selectedCreditCards}
        {...restTableProps}
      />
      <OrganizationCreditCardsTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isShareCreditCardsModalOpen && (
        <ShareVaultsToEmployeeOrGroupSelectionModal
          isOpen={isShareCreditCardsModalOpen}
          onClose={handleShareCreditCardsModalClose}
          onSelect={handleShareCreditCardsModalTypeChange}
          type={VaultTypeEnum.CreditCard}
        />
      )}
      {shareCreditCardsModalType === Ownership.Employees && (
        <ShareVaultsToEmployeeModal
          isOpen={shareCreditCardsModalType === Ownership.Employees}
          onClose={handleShareCreditCardsModalTypeClose}
          vaults={selectedCreditCardsAsObjects}
          type={VaultTypeEnum.CreditCard}
          onSubmit={resetSelectedRows}
        />
      )}
      {shareCreditCardsModalType === Ownership.Groups && (
        <ShareVaultsToGroupModal
          isOpen={shareCreditCardsModalType === Ownership.Groups}
          onClose={handleShareCreditCardsModalTypeClose}
          vaults={selectedCreditCardsAsObjects}
          type={VaultTypeEnum.CreditCard}
          onSubmit={resetSelectedRows}
        />
      )}
      {isDetachCreditCardsModalOpen && (
        <PromptModal
          open={isDetachCreditCardsModalOpen}
          onClose={handleDetachCreditCardsModalClose}
          onSubmit={handleDetachCreditCard}
          title={t(
            `organizationCreditCardsTab.detachCreditCardModal.detachFrom${ownership}.title`,
            { count: selectedCreditCards.size },
          )}
          description={t(
            `organizationCreditCardsTab.detachCreditCardModal.detachFrom${ownership}.description`,
          )}
          additionalDescription={t(
            // eslint-disable-next-line max-len
            `organizationCreditCardsTab.detachCreditCardModal.detachFrom${ownership}.additionalDescription`,
          )}
          list={selectedCreditCardsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDetachCreditCardsLoading}
        />
      )}
      {isDeleteCreditCardsModalOpen && (
        <PromptModal
          open={isDeleteCreditCardsModalOpen}
          onClose={handleDeleteCreditCardsModalClose}
          onSubmit={handleDeleteCreditCard}
          title={t(
            'organizationCreditCardsTab.deleteCreditCardModal.title',
            { count: selectedCreditCards.size },
          )}
          description={t(
            'organizationCreditCardsTab.deleteCreditCardModal.description',
          )}
          approvalButtonText="common.delete"
          list={selectedCreditCardsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteCreditCardsLoading}
        />
      )}
      {isCreateCreditCardModalOpen && (
        <CreateCreditCardModal
          isOpen={isCreateCreditCardModalOpen}
          onClose={handleCreateCreditCardModalClose}
          onSubmit={handleCreateCreditCard}
          isLoading={isCreateCreditCardLoading}
        />
      )}
    </PanelContent>
  );
};

export default OrganizationCreditCardsTab;
