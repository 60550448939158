import {
  ScrollablePanel,
  Grid,
  Box,
} from '@uniqkey-frontend/shared-app';
import DashboardPageBreadcrumbs from './components/DashboardPageBreadcrumbs';
import DashboardEmployeesInfoWidget from './components/DashboardEmployeesInfoWidget';
import DashboardEmployeeRateWidgets from './components/DashboardEmployeeRateWidgets';
import DashboardSecurityScoreWidgets from './components/DashboardSecurityScoreWidgets';
import DashboardServicesAndLoginsWidgets from './components/DashboardServicesAndLoginsWidgets';

const DashboardPage = () => (
  <Grid container flexDirection="column" className="height-100-percent">
    <Grid item mb={1}>
      <DashboardPageBreadcrumbs />
    </Grid>
    <Grid item xs minHeight={0}>
      <ScrollablePanel>
        <Grid container p={3} flexDirection="column">
          <DashboardEmployeesInfoWidget />
          <Box mt={3} />
          <DashboardEmployeeRateWidgets />
        </Grid>
        <Grid container p={3} pt={0} flexDirection="column">
          <DashboardSecurityScoreWidgets />
        </Grid>
        <Grid container p={3} pt={0} flexDirection="column">
          <DashboardServicesAndLoginsWidgets />
        </Grid>
      </ScrollablePanel>
    </Grid>
  </Grid>
);

export default DashboardPage;
