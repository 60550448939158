import { memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  useTranslations,
  validateDomain,
  S6,
  maxLengthValidator,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';

export interface IAddVerifiedDomainModalReturnValue {
  domainName: string;
}

interface IAddVerifiedDomainModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (params: IAddVerifiedDomainModalReturnValue) => Promise<void>;
}

const AddVerifiedDomainModal = (props: IAddVerifiedDomainModalProps) => {
  const {
    isOpen, isLoading, onClose, onSubmit,
  } = props;
  const { t } = useTranslations();
  const {
    control, handleSubmit, formState: { errors, isDirty },
  } = useForm<IAddVerifiedDomainModalReturnValue>({
    defaultValues: {
      domainName: '',
    },
  });

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('addVerifiedDomainModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color={S6}
              >
                {t('addVerifiedDomainModal.description')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="domainName"
                control={control}
                fullWidth
                autoFocus
                error={!!errors.domainName}
                label={t('addVerifiedDomainModal.label')}
                placeholder={t('addVerifiedDomainModal.placeholder')}
                helperText={errors.domainName?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateDomain(v)
                        || t('validation.invalidDomain'),
                    maxLength: (v) => maxLengthValidator(253, v)
                        || t('validation.maxLength', { length: 253 }),
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                disabled={!isDirty}
                isLoading={isLoading}
                type="submit"
              >
                {t('common.add')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                disabled={isLoading}
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(AddVerifiedDomainModal);
