import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetEmployeeAccountByIdResponse,
  InviteEmployeeRequestModel,
  GetEmployeeAccountsResponse,
  InviteBulkEmployeeResponse,
  NoContentResult,
  DeleteBulkEmployeeAccountRequest,
  DeleteBulkEmployeeAccountResponse,
  GetByApplicationIdResponse,
  GetAverageSecurityScoreResponse,
  GetManagedByEmployeesByVaultIdResponse,
  GetManagedByGroupsByVaultIdResponse,
  Operation,
  ArchiveBulkEmployeeAccountRequest,
  ArchiveBulkEmployeeAccountResponse,
  SendBulkInviteResponse,
  RestoreBulkEmployeeAccountRequest,
  RestoreBulkEmployeeAccountResponse,
  MeResponse,
  EncryptionKeyType,
  SendBulkInviteRequest,
  RevokeAdminResponse,
  PromoteAdminResponse,
  GetUnprocessedSCIMUsersCountResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import {
  TGetEmployeeAccountsByApplicationIdMethod,
  TGetEmployeeAccountsMethod,
  TGetEmployeeAccountsManagedByEmployeesByVaultIdMethod,
  TGetEmployeesManagedByGroupsByVaultIdMethod,
} from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';
import {
  IInviteEmployeesParams,
} from '../../pages/EmployeesPage/components/InviteEmployeesModal/interfaces';

const useEmployeeAccountsAPI = () => {
  const { employeeAccountsAPIClient } = useApiClients();

  const inviteBulk = useCallback(async (
    params: IInviteEmployeesParams,
    options?: AxiosRequestConfig,
  ): Promise<InviteBulkEmployeeResponse> => {
    const { shouldInvite, language, users } = params;
    const employeeAccounts: InviteEmployeeRequestModel[] = users.map(({ email, name }) => ({
      email: email.toLowerCase(), name, language,
    }));
    return employeeAccountsAPIClient
      .apiV1EmployeeAccountsInviteBulkPost({ shouldInvite, employeeAccounts }, options)
      .then(dataExtractor<InviteBulkEmployeeResponse>());
  }, [employeeAccountsAPIClient]);

  const getEmployeeAccounts = useCallback<TGetEmployeeAccountsMethod>(
    (params) => {
      const {
        page,
        pageLength,
        typeFilter = undefined,
        groupId = undefined,
        statusFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        maxStrength = undefined,
        minStrength = undefined,
        activityFilter = undefined,
        cancelToken = undefined,
      } = params;
      return employeeAccountsAPIClient.apiV1EmployeeAccountsGet(
        page,
        pageLength,
        typeFilter,
        groupId,
        statusFilter,
        searchQuery,
        orderPropertyName,
        isDescending,
        maxStrength,
        minStrength,
        activityFilter,
        { cancelToken },
      ).then(dataExtractor<GetEmployeeAccountsResponse>());
    },
    [employeeAccountsAPIClient],
  );

  const getEmployeeAccountsByApplicationId = useCallback<TGetEmployeeAccountsByApplicationIdMethod>(
    (params) => {
      const {
        page,
        pageLength,
        applicationId,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return employeeAccountsAPIClient.apiV1EmployeeAccountsGetByApplicationIdGet(
        page,
        pageLength,
        applicationId,
        searchQuery,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetByApplicationIdResponse>());
    },
    [employeeAccountsAPIClient],
  );

  const getEmployeeAccountsManagedByEmployeesByVaultId = useCallback<
    TGetEmployeeAccountsManagedByEmployeesByVaultIdMethod
  >(
    (params) => {
      const {
        vaultId,
        page,
        pageLength,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return employeeAccountsAPIClient
        .apiV1EmployeeAccountsGetManagedByEmployeesByVaultIdVaultIdGet(
          vaultId,
          page,
          pageLength,
          searchQuery,
          orderPropertyName,
          isDescending,
          { cancelToken },
        ).then(dataExtractor<GetManagedByEmployeesByVaultIdResponse>());
    },
    [employeeAccountsAPIClient],
  );

  const getEmployeesManagedByGroupsByVaultId = useCallback<
    TGetEmployeesManagedByGroupsByVaultIdMethod
  >((
    params,
  ) => {
    const {
      vaultId,
      page,
      pageLength,
      searchQuery = undefined,
      orderPropertyName = undefined,
      isDescending = undefined,
      cancelToken = undefined,
    } = params;
    return employeeAccountsAPIClient.apiV1EmployeeAccountsGetManagedByGroupsByVaultIdVaultIdGet(
      vaultId,
      page,
      pageLength,
      searchQuery,
      orderPropertyName,
      isDescending,
      { cancelToken },
    ).then(dataExtractor<GetManagedByGroupsByVaultIdResponse>());
  }, [employeeAccountsAPIClient]);

  const getEmployeeAccountById = useCallback(
    (
      employeeAccountId: string,
      options?: AxiosRequestConfig,
    ): Promise<GetEmployeeAccountByIdResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsEmployeeAccountIdGet(employeeAccountId, options)
      .then(dataExtractor<GetEmployeeAccountByIdResponse>()),
    [employeeAccountsAPIClient],
  );

  const getCurrentEmployee = useCallback(
    (options?: AxiosRequestConfig)
      : Promise<MeResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsMeGet(options)
      .then(dataExtractor<MeResponse>()),
    [employeeAccountsAPIClient],
  );

  const patchEmployeeAccountById = useCallback(
    (employeeAccountId: string, operations: Array<Operation>, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsEmployeeAccountIdPatch(employeeAccountId, operations, options)
      .then(dataExtractor<NoContentResult>()),
    [employeeAccountsAPIClient],
  );

  const promoteAdmin = useCallback(
    (
      employeeAccountId: string,
      encryptionKeyType?: EncryptionKeyType,
      options?: AxiosRequestConfig,
    ): Promise<PromoteAdminResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsPromoteAdminPost(employeeAccountId, encryptionKeyType, options)
      .then(dataExtractor<PromoteAdminResponse>()),
    [employeeAccountsAPIClient],
  );

  const revokeAdmin = useCallback(
    (
      employeeAccountId: string,
      encryptionKeyType?: EncryptionKeyType,
      options?: AxiosRequestConfig,
    ): Promise<RevokeAdminResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsRevokeAdminPost(employeeAccountId, encryptionKeyType, options)
      .then(dataExtractor<RevokeAdminResponse>()),
    [employeeAccountsAPIClient],
  );

  const archiveEmployeeAccounts = useCallback(
    (
      archiveBulkEmployeeAccountRequest: ArchiveBulkEmployeeAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<ArchiveBulkEmployeeAccountResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsArchiveBulkPost(archiveBulkEmployeeAccountRequest, options)
      .then(dataExtractor<ArchiveBulkEmployeeAccountResponse>()),
    [employeeAccountsAPIClient],
  );

  const deleteEmployeeAccounts = useCallback(
    (
      deleteBulkEmployeeAccountRequest: DeleteBulkEmployeeAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<DeleteBulkEmployeeAccountResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsDeleteBulkPost(deleteBulkEmployeeAccountRequest, options)
      .then(dataExtractor<DeleteBulkEmployeeAccountResponse>()),
    [employeeAccountsAPIClient],
  );

  const getAverageSecurityScore = useCallback(
    (employeeAccountId: string, options?: AxiosRequestConfig)
      : Promise<GetAverageSecurityScoreResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsGetAverageSecurityScoreEmployeeAccountIdGet(employeeAccountId, options)
      .then(dataExtractor<GetAverageSecurityScoreResponse>()),
    [employeeAccountsAPIClient],
  );

  const sendInviteBulk = useCallback(
    (sendBulkInviteRequest: SendBulkInviteRequest, options?: AxiosRequestConfig)
      : Promise<SendBulkInviteResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsBulkSendInvitesPost(sendBulkInviteRequest, options)
      .then(dataExtractor<SendBulkInviteResponse>()),
    [employeeAccountsAPIClient],
  );

  const restoreEmployeeAccounts = useCallback(
    (
      restoreBulkEmployeeAccountRequest: RestoreBulkEmployeeAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<RestoreBulkEmployeeAccountResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsRestoreBulkPost(restoreBulkEmployeeAccountRequest, options)
      .then(dataExtractor<RestoreBulkEmployeeAccountResponse>()),
    [employeeAccountsAPIClient],
  );

  const getUnprocessedSCIMUsersCount = useCallback(
    (
      options?: AxiosRequestConfig,
    ): Promise<GetUnprocessedSCIMUsersCountResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsGetUnprocessedSCIMUsersCountGet(options)
      .then(dataExtractor<GetUnprocessedSCIMUsersCountResponse>()),
    [employeeAccountsAPIClient],
  );

  return ({
    inviteBulk,
    getCurrentEmployee,
    getEmployeeAccounts,
    getEmployeeAccountsByApplicationId,
    getEmployeeAccountsManagedByEmployeesByVaultId,
    getEmployeesManagedByGroupsByVaultId,
    getEmployeeAccountById,
    patchEmployeeAccountById,
    promoteAdmin,
    revokeAdmin,
    archiveEmployeeAccounts,
    deleteEmployeeAccounts,
    getAverageSecurityScore,
    sendInviteBulk,
    restoreEmployeeAccounts,
    getUnprocessedSCIMUsersCount,
  });
};

export default useEmployeeAccountsAPI;
