import {
  memo, useCallback, useMemo, useState,
} from 'react';
import {
  Box,
  EditableContainer,
  EntityDetailsContainer,
  Grid,
  Label,
  Typography,
  useSnackbar,
  useTranslations,
  formatDate,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import { GetGroupByIdResponse } from '@uniqkey-backend-organization-web/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { useGetGroupById, usePatchGroupById } from '../../../../hooks/reactQuery';
import EditGroupModal, { IEditGroupModalSubmitResult } from '../EditGroupModal';
import { createReplacePatchOperation } from '../../../../helpers/apiClients';

interface IGroupDetailsWidgetProps {
  groupId: string;
}

const GroupDetailsWidget = (props: IGroupDetailsWidgetProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
  const [isEditGroupLoading, setIsEditGroupLoading] = useState(false);
  const handleEditGroupModalClose = useCallback(() => setIsEditGroupModalOpen(false), []);
  const handleEditGroupModalOpen = useCallback(() => setIsEditGroupModalOpen(true), []);
  const {
    data: group, isLoading, isError, refetch,
  } = useGetGroupById(
    { groupId },
  );

  const handleRefetch = useCallback(() => refetch(), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_GROUP, handleRefetch);

  const {
    name,
    description,
    createdAt,
    externalCreationId,
    canManageVaults,
    createdByEmployeeAccountName,
    createdByEmployeeAccountEmail,
  } = group ?? {} as GetGroupByIdResponse;
  const creationDate = useMemo(() => formatDate({ date: createdAt }), [createdAt]);

  const { showError } = useSnackbar();
  const { mutate } = usePatchGroupById({ groupId, useOptimisticUpdates: true });
  const handleGroupEdit = useCallback((value: IEditGroupModalSubmitResult) => {
    setIsEditGroupLoading(true);
    mutate([
      createReplacePatchOperation('/name', value.name),
      createReplacePatchOperation('/description', value.description),
    ], {
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSuccess: () => handleEditGroupModalClose(),
      onSettled: () => setIsEditGroupLoading(false),
    });
  }, [mutate, handleEditGroupModalClose, showError, t]);

  if (isError) {
    return null;
  }

  return (
    <EntityDetailsContainer container justifyContent="space-between" isLoading={isLoading}>
      <Grid item xs={8} container flexDirection="column">
        <Box mb={3} mr={1} width="100%">
          <EditableContainer
            item
            container
            flexDirection="column"
            p={1}
            disabled={!!externalCreationId}
            onClick={handleEditGroupModalOpen}
          >
            <Typography variant="h6" noWrap width="100%">
              {name}
            </Typography>
            {!!description && (
              <>
                <Box mt={1} />
                <Typography lineClamp={3}>
                  {description}
                </Typography>
              </>
            )}
          </EditableContainer>
        </Box>
        <Grid item container flexDirection="column">
          <Grid item container>
            <Typography variant="subtitle1">
              {t('groupDetailsWidget.created')}
              :
            </Typography>
            &nbsp;
            <Typography>{creationDate}</Typography>
          </Grid>
          {!externalCreationId && (
            <Grid item container>
              <Typography variant="subtitle1">
                {t('groupDetailsWidget.createdBy')}
                :
              </Typography>
              &nbsp;
              <Typography noWrap>
                {createdByEmployeeAccountName ?? createdByEmployeeAccountEmail}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4} container flexDirection="column" alignItems="end">
        {canManageVaults && (
          <Box mb={1}><Label text={t('groupDetailsWidget.canManageLogins')} /></Box>
        )}
        {!!externalCreationId && <Label text={t('groupDetailsWidget.scim')} />}
      </Grid>
      {isEditGroupModalOpen && (
        <EditGroupModal
          isOpen={isEditGroupModalOpen}
          onClose={handleEditGroupModalClose}
          onSubmit={handleGroupEdit}
          name={name}
          description={description}
          isLoading={isEditGroupLoading}
        />
      )}
    </EntityDetailsContainer>
  );
};

export default memo(GroupDetailsWidget);
