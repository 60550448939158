import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  usePubSub,
  buildFilterOptionsAsObject,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { IGetGroupsParams } from '../../../../../../hooks/useEmployeeGroupsAPI/interfaces';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';

export interface IEmployeeGroupsTabFilterSubmitResult {
  canManageVaults: IGetGroupsParams['canManageVaults'];
}

interface IEmployeeGroupsTabFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IEmployeeGroupsTabFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IEmployeeGroupsTabFilterSubmitResult;
}

type TCanManageVaultsOption = {
  label: string;
  value: IEmployeeGroupsTabFilterSubmitResult['canManageVaults'];
} | null;

interface IFormValues {
  canManageVaults: TCanManageVaultsOption;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const EmployeeGroupsTabFilter = (props: IEmployeeGroupsTabFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();

  const [canManageVaultsOptions, canManageVaultsOptionsAsObject] = useMemo(() => {
    const options = [
      { label: t('groupsFilter.loginsManagement.values.enabled'), value: true },
      { label: t('groupsFilter.loginsManagement.values.disabled'), value: false },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      canManageVaults: canManageVaultsOptionsAsObject[
        initialValues.canManageVaults?.toString() as keyof typeof canManageVaultsOptionsAsObject
      ] ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { canManageVaults } = value;
    onSubmit({ canManageVaults: canManageVaults?.value });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('canManageVaults', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      canManageVaults: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center" columnSpacing={4}>
              <Grid item xs={3}>
                <Typography>
                  {t('groupsFilter.loginsManagement.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="canManageVaults"
                  options={canManageVaultsOptions}
                  placeholder={t('groupsFilter.loginsManagement.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(EmployeeGroupsTabFilter);
