import { useCallback } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import {
  Grid,
  WidgetContainer,
  TypographyWithHelp,
  useTranslations,
  S6,
  useSnackbar,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountStatus,
  ActivityFilter,
} from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import DashboardEmployeesInfoWidgetItem from './components/DashboardEmployeesInfoWidgetItem';
import DashboardEmployeesInfoWidgetActiveUsersItem
  from './components/DashboardEmployeesInfoWidgetActiveUsersItem';
import { useGetEmployeeAccountsOnBoard } from '../../../../hooks/reactQuery';

const DEFAULT_VALUES = {
  employeeAccountsTotalCount: 0,
  employeeAccountsActiveStatusCount: 0,
  employeeAccountsInvitedCount: 0,
  employeeAccountsNotActiveLast30DaysCount: 0,
  employeeAccountsStagedCount: 0,
};

const DashboardEmployeesInfoWidget = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { data: employeeAccountsOnBoard, isLoading, isError } = useGetEmployeeAccountsOnBoard({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const handleNavigation = useCallback((statusFilter: EmployeeAccountStatus) => {
    const search = createSearchParams({ statusFilter }).toString();
    navigate({
      pathname: PageRouteEnum.Employees,
      search,
    });
  }, [navigate]);

  const handleActiveTotalUsersLinkClick = useCallback(() => {
    handleNavigation(EmployeeAccountStatus.Active);
  }, [handleNavigation]);

  const handleStagedLinkClick = useCallback(() => {
    handleNavigation(EmployeeAccountStatus.Staged);
  }, [handleNavigation]);

  const handleNotActivatedLinkClick = useCallback(() => {
    handleNavigation(EmployeeAccountStatus.Invited);
  }, [handleNavigation]);

  const handleNoActivityLinkClick = useCallback(() => {
    const search = createSearchParams({ activityFilter: ActivityFilter.NotActive }).toString();
    navigate({
      pathname: PageRouteEnum.Employees,
      search,
    });
  }, [navigate]);

  if (isError) {
    return null;
  }

  const {
    employeeAccountsTotalCount,
    employeeAccountsActiveStatusCount,
    employeeAccountsInvitedCount,
    employeeAccountsNotActiveLast30DaysCount,
    employeeAccountsStagedCount,
  } = employeeAccountsOnBoard ?? DEFAULT_VALUES;

  return (
    <WidgetContainer container withShadow isLoading={isLoading} rowGap={2}>
      <Grid item>
        <TypographyWithHelp helperText={t('dashboardEmployeesInfoWidget.tooltip')} color={S6}>
          {t('dashboardEmployeesInfoWidget.title')}
        </TypographyWithHelp>
      </Grid>
      <Grid item container>
        <DashboardEmployeesInfoWidgetActiveUsersItem
          onClick={handleActiveTotalUsersLinkClick}
          activeTitle={t('dashboardEmployeesInfoWidget.activeTotalUsers.active')}
          totalTitle={t('dashboardEmployeesInfoWidget.activeTotalUsers.total')}
          titleHelperText={t('dashboardEmployeesInfoWidget.activeTotalUsers.tooltip')}
          linkText={t('dashboardEmployeesInfoWidget.activeTotalUsers.link')}
          activeCount={employeeAccountsActiveStatusCount}
          totalCount={employeeAccountsTotalCount}
          showDivider
        />
        <DashboardEmployeesInfoWidgetItem
          onClick={handleStagedLinkClick}
          title={t('dashboardEmployeesInfoWidget.staged.title')}
          titleHelperText={t('dashboardEmployeesInfoWidget.staged.tooltip')}
          linkText={t('dashboardEmployeesInfoWidget.staged.link')}
          count={employeeAccountsStagedCount}
          showDivider
        />
        <DashboardEmployeesInfoWidgetItem
          onClick={handleNotActivatedLinkClick}
          title={t('dashboardEmployeesInfoWidget.notActivated.title')}
          titleHelperText={t('dashboardEmployeesInfoWidget.notActivated.tooltip')}
          linkText={t('dashboardEmployeesInfoWidget.notActivated.link')}
          count={employeeAccountsInvitedCount}
          showDivider
        />
        <DashboardEmployeesInfoWidgetItem
          onClick={handleNoActivityLinkClick}
          title={t('dashboardEmployeesInfoWidget.noActivity.title')}
          titleHelperText={t('dashboardEmployeesInfoWidget.noActivity.tooltip')}
          linkText={t('dashboardEmployeesInfoWidget.noActivity.link')}
          count={employeeAccountsNotActiveLast30DaysCount}
          showDivider={false}
        />
      </Grid>
    </WidgetContainer>
  );
};

export default DashboardEmployeesInfoWidget;
