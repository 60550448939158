import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import {
  CheckResponse, GetOrganizationsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQuery } from 'react-query';
import useMigrationAPI from '../../useMigrationAPI';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

export const useGetOrganizations = (
  options: TUseQueryOptions<GetOrganizationsResponse> = {},
) => {
  const { getOrganizations } = useMigrationAPI();
  return useQuery<GetOrganizationsResponse>(
    ReactQueryKeyEnum.Organizations,
    ({ signal }) => getOrganizations({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetEmployeesParams {
  organizationId: string,
  masterPasswordHash: string,
}

export const useGetEmployees = (
  params: IUseGetEmployeesParams,
  options: TUseQueryOptions<CheckResponse> = {},
) => {
  const { organizationId, masterPasswordHash } = params;
  const { check } = useMigrationAPI();
  return useQuery<CheckResponse>(
    [ReactQueryKeyEnum.Employees, organizationId],
    ({ signal }) => check(organizationId, masterPasswordHash, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
