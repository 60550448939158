import { useCallback, memo } from 'react';
import {
  CircularProgress,
  Grid,
  PanelContent,
  ScrollablePanelContent,
  ToggledSetting,
  ToggledSettings,
  useSnackbar,
  useTranslations,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import GroupPolicyEnum from '../../../../enums/GroupPolicyEnum';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  useGetGroupById,
  useGetOrganizationSecuritySettings,
  usePatchGroupById,
} from '../../../../hooks/reactQuery';
import {
  createReplacePatchOperation,
  IReplacePatchOperation,
} from '../../../../helpers/apiClients';

interface IGroupSettingsTabProps {
  groupId: string;
}

const GroupSettingsTab = (props: IGroupSettingsTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();

  const {
    data: organizationSettings,
    // used to show spinner after each time the tab is opened
    isFetchedAfterMount: areOrganizationSettingsFetchedAfterMount,
    isError: isOrganizationSettingsError,
    refetch: organizationSettingsRefetch,
  } = useGetOrganizationSecuritySettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const {
    data: group,
    // used to show spinner after each time the tab is opened
    isFetchedAfterMount: isGroupFetchedAfterMount,
    isError: isGroupError,
    refetch: groupRefetch,
  } = useGetGroupById(
    { groupId },
  );

  const handleGroupRefetch = useCallback(() => groupRefetch(), [groupRefetch]);
  const handleOrganizationSettingsRefetch = useCallback(
    () => organizationSettingsRefetch(),
    [organizationSettingsRefetch],
  );
  usePubSub(
    PubSubEventEnum.DATA_SYNCHRONIZATION_GROUP,
    handleGroupRefetch,
  );
  usePubSub(
    PubSubEventEnum.DATA_SYNCHRONIZATION_SECURITY_SETTINGS,
    handleOrganizationSettingsRefetch,
  );

  const { mutate, isLoading: isUpdating } = usePatchGroupById({
    groupId,
    useOptimisticUpdates: true,
  });

  const handleSettingChange = useCallback(
    (operations: IReplacePatchOperation[]) => {
      mutate(operations, {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('common.settingsUpdatedToast') });
        },
      });
    },
    [mutate, showError, showSuccess, t],
  );

  const handleAllowManageSecureDataChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    handleSettingChange([
      createReplacePatchOperation(GroupPolicyEnum.CanManageVaults, checked),
      createReplacePatchOperation(GroupPolicyEnum.CanView, checked),
      createReplacePatchOperation(GroupPolicyEnum.CanCopy, checked),
      createReplacePatchOperation(GroupPolicyEnum.CanAdd, checked),
      createReplacePatchOperation(GroupPolicyEnum.CanEdit, checked),
      createReplacePatchOperation(GroupPolicyEnum.CanDelete, checked),
    ]);
  }, [isUpdating, handleSettingChange]);

  const handleAllowTrustedDevicesChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    handleSettingChange([
      createReplacePatchOperation(GroupPolicyEnum.TrustedBrowserAllowed, checked),
    ]);
  }, [isUpdating, handleSettingChange]);

  const handleViewAndCopyAllowedChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    const operations = [
      createReplacePatchOperation(GroupPolicyEnum.CanView, checked),
      createReplacePatchOperation(GroupPolicyEnum.CanCopy, checked),
    ];
    if (!checked) {
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanAdd, false));
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanEdit, false));
    }
    handleSettingChange(operations);
  }, [isUpdating, handleSettingChange]);

  const handleAddAndEditAllowedChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    const operations = [
      createReplacePatchOperation(GroupPolicyEnum.CanAdd, checked),
      createReplacePatchOperation(GroupPolicyEnum.CanEdit, checked),
    ];
    if (checked) {
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanView, true));
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanCopy, true));
    }
    handleSettingChange(operations);
  }, [isUpdating, handleSettingChange]);

  const handleDeleteAllowedChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    const operations = [
      createReplacePatchOperation(GroupPolicyEnum.CanDelete, checked),
    ];
    if (checked) {
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanView, true));
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanCopy, true));
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanAdd, true));
      operations.push(createReplacePatchOperation(GroupPolicyEnum.CanEdit, true));
    }
    handleSettingChange(operations);
  }, [isUpdating, handleSettingChange]);

  const handleAllowExportLoginsChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    handleSettingChange([
      createReplacePatchOperation(GroupPolicyEnum.IsExportAllowed, checked),
    ]);
  }, [isUpdating, handleSettingChange]);

  if (isGroupError || isOrganizationSettingsError) {
    return null;
  }

  if (!isGroupFetchedAfterMount || !areOrganizationSettingsFetchedAfterMount) {
    return (
      <PanelContent p={3} className="height-100-percent">
        <Grid container alignItems="center" justifyContent="center" height="inherit">
          <CircularProgress />
        </Grid>
      </PanelContent>
    );
  }

  const {
    hasSecureData,
    canManageVaults,
    trustedBrowserAllowed: groupTrustedBrowserAllowed,
    canView,
    canCopy,
    canAdd,
    canEdit,
    canDelete,
    isExportAllowed,
  } = group ?? {};
  const {
    trustedBrowserAllowed: organizationTrustedBrowserAllowed,
    exportAllowed: organizationExportAllowed,
  } = organizationSettings ?? {};

  const allowManageSecureDataTooltip = hasSecureData
    ? t('groupPage.settingsTab.tooltips.groupContainsLogins')
    : undefined;
  const trustedBrowserAllowedTooltip = !organizationTrustedBrowserAllowed
    ? t('groupPage.settingsTab.tooltips.trustedDevicesDisabledInOrganization')
    : undefined;
  const policiesTooltip = !canManageVaults
    ? t('groupPage.settingsTab.tooltips.groupIsNotManagingSecureData')
    : undefined;
  const exportLoginsAllowedTooltip = !organizationExportAllowed
    ? t('groupPage.settingsTab.tooltips.exportLoginsDisabledInOrganization')
    : undefined;

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container spacing={2} className="height-100-percent">
        <Grid container item xs={6} flexDirection="column">
          <ToggledSettings>
            <ToggledSetting
              name="allowManageSecureData"
              title={t('groupPage.settingsTab.settings.allowManageSecureData.title')}
              description={t('groupPage.settingsTab.settings.allowManageSecureData.description')}
              checked={canManageVaults}
              disabled={hasSecureData}
              onChange={handleAllowManageSecureDataChange}
              tooltip={allowManageSecureDataTooltip}
            />
            <ToggledSetting
              name="allowTrustedDevices"
              title={t('groupPage.settingsTab.settings.allowTrustedDevices.title')}
              description={t('groupPage.settingsTab.settings.allowTrustedDevices.description')}
              checked={!organizationTrustedBrowserAllowed ? false : groupTrustedBrowserAllowed}
              disabled={!organizationTrustedBrowserAllowed}
              onChange={handleAllowTrustedDevicesChange}
              tooltip={trustedBrowserAllowedTooltip}
            />
            <ToggledSetting
              name="allowExportLogins"
              title={t('groupPage.settingsTab.settings.allowExportLogins.title')}
              description={t('groupPage.settingsTab.settings.allowExportLogins.description')}
              checked={!organizationExportAllowed ? false : isExportAllowed}
              disabled={!organizationExportAllowed}
              onChange={handleAllowExportLoginsChange}
              tooltip={exportLoginsAllowedTooltip}
            />
          </ToggledSettings>
        </Grid>
        <Grid container item xs={6} flexDirection="column">
          <ToggledSettings item xs alignContent="flex-start">
            <ToggledSetting
              name="viewAndCopyAllowed"
              title={t('groupPage.settingsTab.settings.viewAndCopyAllowed.title')}
              description={t('groupPage.settingsTab.settings.viewAndCopyAllowed.description')}
              checked={canView && canCopy}
              disabled={!canManageVaults}
              onChange={handleViewAndCopyAllowedChange}
              tooltip={policiesTooltip}
            />
            <ToggledSetting
              name="addAndEditAllowed"
              title={t('groupPage.settingsTab.settings.addAndEditAllowed.title')}
              description={t('groupPage.settingsTab.settings.addAndEditAllowed.description')}
              checked={canAdd && canEdit}
              disabled={!canManageVaults}
              onChange={handleAddAndEditAllowedChange}
              tooltip={policiesTooltip}
            />
            <ToggledSetting
              name="deleteAllowed"
              title={t('groupPage.settingsTab.settings.deleteAllowed.title')}
              description={t('groupPage.settingsTab.settings.deleteAllowed.description')}
              checked={canDelete}
              disabled={!canManageVaults}
              onChange={handleDeleteAllowedChange}
              tooltip={policiesTooltip}
            />
          </ToggledSettings>
        </Grid>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(GroupSettingsTab);
