import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AO4,
  S5,
  AO3,
  Table,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  SecurityScoreIcon,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
  Typography,
  getTimeFromNow,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountStatus,
  EmployeeAccountType,
  GetEmployeeAccountsResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import {
  EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS,
  SCIM_TRANSLATION_KEYS,
  EMPLOYEE_ACCOUNT_TYPE_TRANSLATION_KEYS,
  EMPLOYEE_ACCOUNT_STATUS_COLORS,
} from '../../../constants';
// import OnboardingProgressBar
// from '../../../pages/EmployeesPage/components/OnboardingProgressBar';

export interface IEmployeesTableRow extends GetEmployeeAccountsResponseModel {
  progress: number;
  lastActivity: string | null;
}

interface IEmployeesTableProps extends
  Omit<IUseTableReturn<IEmployeesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetEmployeeAccountsResponseModel>, 'data' | 'refetch'>
{
  employees: IUseTableFetchReturn<GetEmployeeAccountsResponseModel>['data'];
  selectedEmployees: IUseTableReturn<IEmployeesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IEmployeesTableRow> = {
  selection: true,
};

const SHOW_ROLE_COLUMN = {
  [EmployeeAccountStatus.Active]: true,
  [EmployeeAccountStatus.Invited]: true,
  [EmployeeAccountStatus.Archived]: true,
  [EmployeeAccountStatus.Unprocessed]: false,
  [EmployeeAccountStatus.Staged]: true,
  [EmployeeAccountStatus.MigrationInvited]: true,
  [EmployeeAccountStatus.Migrated]: true,
  [EmployeeAccountStatus.ExistingUnprocessed]: false,
};
const SHOW_SECURITY_SCORE_COLUMN = {
  [EmployeeAccountStatus.Active]: true,
  [EmployeeAccountStatus.Invited]: false,
  [EmployeeAccountStatus.Archived]: false,
  [EmployeeAccountStatus.Unprocessed]: false,
  [EmployeeAccountStatus.Staged]: false,
  [EmployeeAccountStatus.MigrationInvited]: true,
  [EmployeeAccountStatus.Migrated]: true,
  [EmployeeAccountStatus.ExistingUnprocessed]: false,
};
const SCIM_COLORS = {
  true: AO4,
  false: S5,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const EmployeesTable = (props: IEmployeesTableProps) => {
  const {
    employees: preEmployees,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedEmployees,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const employees = useMemo(() => preEmployees.map((employee) => ({
    ...employee,
    lastActivity: getTimeFromNow({
      date: employee.activityAt,
      locale: currentLanguage,
    }),
    progress: 0, // TODO: replace with real data
  })), [preEmployees, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('employeesPage.status'),
      field: 'employeeAccountStatus',
      render: (rowData) => {
        if (rowData.employeeAccountStatus === EmployeeAccountStatus.Unprocessed) {
          return (
            <TypographyWithTooltip
              variant="body2"
              color={EMPLOYEE_ACCOUNT_STATUS_COLORS[rowData.employeeAccountStatus]}
              title={t('common.openMobileApp')}
            >
              {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[rowData.employeeAccountStatus])}
            </TypographyWithTooltip>
          );
        }
        if (rowData.employeeAccountStatus === EmployeeAccountStatus.ExistingUnprocessed) {
          return (
            <TypographyWithTooltip
              variant="body2"
              color={EMPLOYEE_ACCOUNT_STATUS_COLORS[rowData.employeeAccountStatus]}
              title={t('employeesPage.existingUnprocessedTooltip')}
            >
              {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[rowData.employeeAccountStatus])}
            </TypographyWithTooltip>
          );
        }
        return (
          <Typography
            variant="body2"
            color={EMPLOYEE_ACCOUNT_STATUS_COLORS[rowData.employeeAccountStatus]}
          >
            {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[rowData.employeeAccountStatus])}
          </Typography>
        );
      },
      width: '15%',
    },
    {
      title: t('employeesPage.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: t('employeesPage.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('employeesPage.role'),
      field: 'employeeAccountType',
      render: (rowData) => SHOW_ROLE_COLUMN[rowData.employeeAccountStatus] && (
        <TypographyWithTooltip
          variant="body2"
          color={rowData.employeeAccountType === EmployeeAccountType.KeylessAdmin ? AO3 : S5}
        >
          {t(EMPLOYEE_ACCOUNT_TYPE_TRANSLATION_KEYS[rowData.employeeAccountType])}
        </TypographyWithTooltip>
      ),
      width: '10%',
    },
    {
      title: t('employeesPage.securityScore'),
      field: 'securityScore',
      render: (rowData) => SHOW_SECURITY_SCORE_COLUMN[rowData.employeeAccountStatus] && (
        <SecurityScoreIcon percentage={rowData.securityScore} />
      ),
      width: '10%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('employeesPage.scim.columnTooltip')}
        >
          {t(SCIM_TRANSLATION_KEYS.true)}
        </TypographyWithTooltip>
      ),
      field: 'isByScim',
      render: (rowData) => (
        <Typography
          variant="body2"
          color={SCIM_COLORS[rowData.isByScim.toString() as keyof typeof SCIM_COLORS]}
        >
          {t(SCIM_TRANSLATION_KEYS[
            rowData.isByScim.toString() as keyof typeof SCIM_TRANSLATION_KEYS
          ])}
        </Typography>
      ),
      width: '10%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('employeesPage.lastActivity.columnTooltip')}
        >
          {t('employeesPage.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    /* {
      // original width
      // field: 'employeeAccountStatus', width: '15%',
      // field: 'email', width: '30%',
      // field: 'employeeAccountType', width: '15%',
      // field: 'securityScore', width: '15%',
      // field: 'progress', width: '25%',

      title: t('employeesPage.onboardingProgress'),
      field: 'progress',
      render: (rowData) => (
        <OnboardingProgressBar progress={rowData.progress} />
      ),
      sorting: false, // TODO: remove when you'll have real data
      width: '25%',
    }, */
  ]), [createColumns, t]);

  const handleEmployeeRowClick = useCallback<
    NonNullable<IMaterialTableProps<IEmployeesTableRow>['onRowClick']>
  >((event, employee) => {
    const { employeeAccountStatus, employeeAccountId } = employee!;
    if (employeeAccountStatus === EmployeeAccountStatus.Unprocessed
      || employeeAccountStatus === EmployeeAccountStatus.ExistingUnprocessed) {
      return;
    }
    navigate(`${PageRouteEnum.Employees}/${employeeAccountId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={employees}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedEmployees}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleEmployeeRowClick}
      rowSelectByKey="employeeAccountId"
      rowHeight={51}
    />
  );
};

export default memo(EmployeesTable);
