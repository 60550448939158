import CryptoJS from 'crypto-js';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import SessionStorageKeyEnum from '../../enums/SessionStorageKeyEnum';

interface IAuthUserParams {
  token: ITokens;
}

export interface ITokens {
  token: string;
  refreshToken: string;
}

export interface ILogoutParams {
  showMessage: boolean;
}

export const getJWTTokens = (): ITokens | null => {
  try {
    const tokens = sessionStorage.getItem(SessionStorageKeyEnum.JWTTokens);
    if (!tokens) return null;
    return JSON.parse(tokens) as ITokens;
  } catch (e) {
    return null;
  }
};

export const setJWTTokens = (tokens: ITokens | null): void => {
  if (!tokens) {
    sessionStorage.removeItem(SessionStorageKeyEnum.JWTTokens);
    return;
  }
  sessionStorage.setItem(SessionStorageKeyEnum.JWTTokens, JSON.stringify(tokens));
};

export const getMasterPasswordHash = (): string | null => (
  sessionStorage.getItem(SessionStorageKeyEnum.MasterPasswordHash)
);
export const setMasterPasswordHash = (masterPasswordHash: string | null): void => {
  if (!masterPasswordHash) {
    sessionStorage.removeItem(SessionStorageKeyEnum.MasterPasswordHash);
    return;
  }
  sessionStorage.setItem(SessionStorageKeyEnum.MasterPasswordHash, masterPasswordHash);
};
export const generateMasterPasswordHash = (password: string): string => CryptoJS
  .SHA3(password, { outputLength: 256 }).toString(CryptoJS.enc.Hex);

export const logout = (params?: ILogoutParams) => {
  setJWTTokens(null);
  setMasterPasswordHash(null);
  PubSub.publish(PubSubEventEnum.LOGOUT, params);
};

export const authUser = async (params: IAuthUserParams) => {
  const { token } = params;
  setJWTTokens(token);
  PubSub.publish(PubSubEventEnum.LOGIN);
};
