import {
  memo, useCallback, useMemo, useState, useEffect, useRef,
} from 'react';
import {
  AG1,
  AG2,
  S4,
  AR1,
  Box,
  EditableContainer,
  EntityDetailsContainer,
  Grid,
  Label,
  S5,
  Switch,
  Typography,
  useSnackbar,
  useTranslations,
  formatDate,
  usePubSub,
  Spinner,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountType,
  EmployeeAccountStatus,
  QueueMessageResultNotification,
  GetEmployeeAccountByIdResponse,
} from '@uniqkey-backend-organization-web/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  useGetEmployeeAccountById,
  usePatchEmployeeAccountById,
  useChangeAdminRights,
} from '../../../../hooks/reactQuery';
import EditEmployeeModal, { IEditEmployeeModalReturnValue } from '../EditEmployeeModal';
import { createReplacePatchOperation } from '../../../../helpers/apiClients';
import { EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS } from '../../../../constants';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import { subscribeToRealtimeAPIEvent } from '../../../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../../../enums/RealtimeAPIEventTypeEnum';
import { logException } from '../../../../services/sentryService';
import QueueEventEnum from '../../../../enums/QueueEventEnum';
import useMobileRequestOverlay from '../../../../hooks/useMobileRequestOverlay';
import MobileRequestOverlay from '../../../../components/MobileRequestOverlay';

interface IEmployeeDetailsWidgetProps {
  employeeAccountId: string;
}

const STATUS_COLORS = {
  [EmployeeAccountStatus.Active]: AG2,
  [EmployeeAccountStatus.Invited]: S5,
  [EmployeeAccountStatus.Archived]: AR1,
  [EmployeeAccountStatus.Unprocessed]: S4,
  [EmployeeAccountStatus.Staged]: S5,
  [EmployeeAccountStatus.MigrationInvited]: S5,
  [EmployeeAccountStatus.Migrated]: S5,
  [EmployeeAccountStatus.ExistingUnprocessed]: S4,
};

const EmployeeDetailsWidget = (props: IEmployeeDetailsWidgetProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();
  const [isEditEmployeeModalOpen, setIsEditEmployeeModalOpen] = useState(false);
  const [isEmployeeEditLoading, setIsEmployeeEditLoading] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const handleEditEmployeeModalClose = useCallback(() => setIsEditEmployeeModalOpen(false), []);
  const handleEditEmployeeModalOpen = useCallback(() => setIsEditEmployeeModalOpen(true), []);
  const handleTimeout = useCallback(() => setIsAdminLoading(false), []);
  const {
    isMobileRequestOverlayOpen,
    mobileRequestOverlayQueueMessageId,
    openMobileRequestOverlay,
    closeMobileRequestOverlay,
    clearMobileRequestOverlayTimeout,
  } = useMobileRequestOverlay({ onTimeout: handleTimeout });

  const trustedPortalQueueMessageId = useRef<string | null>(null);

  const {
    data: employeeAccount, isLoading, isError, refetch,
  } = useGetEmployeeAccountById(
    { employeeAccountId },
  );

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleRefetch = useCallback(() => refetch(), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_EMPLOYEE, handleRefetch);

  const {
    name,
    email,
    employeeAccountType,
    activityAt,
    externalCreationId,
    isLastAdmin,
    employeeAccountStatus,
  } = employeeAccount ?? {} as GetEmployeeAccountByIdResponse;
  const isAdmin = employeeAccountType === EmployeeAccountType.Admin;
  const isSwitchDisabled = employeeAccountStatus !== EmployeeAccountStatus.Active || isLastAdmin;
  const lastActivity = useMemo(() => formatDate({ date: activityAt }), [activityAt]);
  const { showError, showSuccess } = useSnackbar();
  const { mutate: mutateEmployee } = usePatchEmployeeAccountById({
    employeeAccountId,
    useOptimisticUpdates: true,
  });
  const { mutateAsync: mutateAdmin, isLoading: isAdminUpdating } = useChangeAdminRights({
    employeeAccountId,
    promote: !isAdmin,
    useOptimisticUpdates: true,
  });

  const handleAdminRightsChange = useCallback(async () => {
    try {
      if (isAdminUpdating) {
        return;
      }
      setIsAdminLoading(true);
      if (!isTrustedPortalEnabled) {
        clearMobileRequestOverlayTimeout();
      }
      const { queueMessageId: id } = await mutateAdmin(undefined, {
        onError: () => {
          setIsAdminLoading(false);
          showError({ text: t('common.somethingWentWrong') });
        },
      });
      if (!isTrustedPortalEnabled) {
        openMobileRequestOverlay(id);
      } else {
        trustedPortalQueueMessageId.current = id;
      }
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
    }
  }, [
    openMobileRequestOverlay,
    clearMobileRequestOverlayTimeout,
    isAdminUpdating,
    mutateAdmin,
    isTrustedPortalEnabled,
    showError,
    t,
  ]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<QueueMessageResultNotification>(
      RealtimeAPIEventTypeEnum.QueueMessageResultNotification,
      async (event) => {
        try {
          const { queueEvent, queueMessageId, status } = event;
          if (
            queueMessageId !== mobileRequestOverlayQueueMessageId
            && queueMessageId !== trustedPortalQueueMessageId.current
          ) {
            return;
          }
          if (!isTrustedPortalEnabled) {
            closeMobileRequestOverlay();
          } else {
            trustedPortalQueueMessageId.current = null;
          }
          let successMessageTranslationKey = '';
          let errorMessageTranslationKey = '';
          // @ts-ignore TODO: NEXTGEN-6458 TODO: NEXTGEN-6479
          if (queueEvent === QueueEventEnum.promoteAdmin) {
            successMessageTranslationKey = 'mobileRequestNotifications.promoteAdminApproved';
            errorMessageTranslationKey = 'mobileRequestNotifications.promoteAdminRejected';
          // @ts-ignore TODO: NEXTGEN-6458 TODO: NEXTGEN-6479
          } else if (queueEvent === QueueEventEnum.revokeAdmin) {
            successMessageTranslationKey = 'mobileRequestNotifications.revokeAdminApproved';
            errorMessageTranslationKey = 'mobileRequestNotifications.revokeAdminRejected';
          }
          if (status) {
            showError({ text: t(errorMessageTranslationKey) });
          } else {
            showSuccess({ text: t(successMessageTranslationKey) });
          }
          refetch();
          setIsAdminLoading(false);
        } catch (e) {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message:
            // eslint-disable-next-line max-len
              'EmployeeDetailsWidget/RealtimeAPIEventTypeEnum.QueueMessageResultNotification exception',
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [
    mobileRequestOverlayQueueMessageId,
    closeMobileRequestOverlay,
    showSuccess,
    showError,
    refetch,
    isTrustedPortalEnabled,
    t,
  ]);

  const handleCloseMobileRequestOverlay = useCallback(() => {
    setIsAdminLoading(false);
    closeMobileRequestOverlay();
  }, [closeMobileRequestOverlay]);

  const handleEmployeeEdit = useCallback((value: IEditEmployeeModalReturnValue) => {
    setIsEmployeeEditLoading(true);
    mutateEmployee([
      createReplacePatchOperation('/name', value.name),
    ], {
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSuccess: () => handleEditEmployeeModalClose(),
      onSettled: () => setIsEmployeeEditLoading(false),
    });
  }, [mutateEmployee, handleEditEmployeeModalClose, showError, t]);

  if (isError) {
    return null;
  }

  return (
    <EntityDetailsContainer container isLoading={isLoading}>
      <Grid item xs={8} container flexDirection="column">
        <Box mt={-1} ml={-1}>
          <EditableContainer
            container
            onClick={handleEditEmployeeModalOpen}
            disabled={!!externalCreationId}
          >
            <Typography variant="subtitle1" noWrap width="100%">
              {name || t('employeeDetailsWidget.name')}
            </Typography>
          </EditableContainer>
        </Box>
        <Grid item>
          <Typography variant="body1" color={S5}>
            {email}
          </Typography>
        </Grid>
        <Box mt={1} />
        <Grid item>
          {!!externalCreationId && <Label text={t('employeeDetailsWidget.scim')} />}
        </Grid>
        <Box mt={1} />
        <Grid item container alignItems="center">
          {isAdminLoading ? (
            <Grid item mt="9px">
              <Spinner />
            </Grid>
          ) : (
            <>
              <Grid item>
                <Typography variant="subtitle1" color={isAdmin ? AG1 : S4}>
                  {t('employeeDetailsWidget.admin')}
                </Typography>
              </Grid>
              <Box mr={2} />
              <Switch
                checked={isAdmin}
                disabled={isSwitchDisabled}
                onChange={handleAdminRightsChange}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        container
        flexDirection="column"
        justifyContent="space-between"
        alignItems="end"
      >
        <Grid item>
          <Typography variant="subtitle1" color={STATUS_COLORS[employeeAccountStatus!]}>
            {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[employeeAccountStatus!])}
          </Typography>
        </Grid>
        <Grid item>
          <Grid item flexDirection="column" textAlign="end">
            <Typography variant="caption" color={S5}>
              {t('employeeDetailsWidget.lastActivity')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={S5}>
              {lastActivity}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isEditEmployeeModalOpen && (
        <EditEmployeeModal
          isOpen={isEditEmployeeModalOpen}
          onClose={handleEditEmployeeModalClose}
          onSubmit={handleEmployeeEdit}
          // TODO: NEXTGEN-6338, remove ts-ignore
          // @ts-ignore
          name={name}
          isLoading={isEmployeeEditLoading}
        />
      )}
      <MobileRequestOverlay
        queueMessageId={mobileRequestOverlayQueueMessageId!}
        isOpen={isMobileRequestOverlayOpen}
        onCancel={handleCloseMobileRequestOverlay}
      />
    </EntityDetailsContainer>
  );
};

export default memo(EmployeeDetailsWidget);
