import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  Grid,
  InviteUserIcon,
  Panel,
  useSnackbar,
  useTranslations,
  usePopper,
  FilterButton,
  ActionButton,
  GroupIcon,
  SearchableTextField,
  Divider,
  MessageIcon,
  ArchiveIcon,
  Tooltip,
  DeleteIcon,
  usePubSub,
  RecoverIcon,
  useMapKeyValueExtractor,
  PromptModal,
  RedirectIcon,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import {
  ArchiveBulkEmployeeAccountErrorDetails,
  EmployeeAccountStatus,
} from '@uniqkey-backend-organization-web/api-client';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import InviteEmployeesModal from './components/InviteEmployeesModal';
import useEmployeeAccountsAPI from '../../hooks/useEmployeeAccountsAPI';
import EmployeesListFilter, {
  IEmployeesListFilterSubmitResult,
} from './components/EmployeesListFilter';
import GroupsSelectorModal from './components/GroupsSelectorModal';
import EmployeesTable, { IEmployeesTableRow } from '../../components/tables/EmployeesTable';
import useEmployeesTable from '../../hooks/tables/useEmployeesTable';
import {
  useDeleteEmployeeAccounts,
  useGetOrganizationSecuritySettings,
  useGetUnprocessedSCIMUsersCount,
  useLanguages,
} from '../../hooks/reactQuery';
import { IInviteEmployeesParams } from './components/InviteEmployeesModal/interfaces';
import SendInviteModal from './components/SendInviteModal';
import { logException } from '../../services/sentryService';
import { generateTooltipTitle } from '../../helpers/tooltips';
import UnprocessedEmployeesModal from './components/UnprocessedEmployeesModal';
import {
  getShouldShowUnprocessedEmployeesWarningByOrganizationId,
  setShouldShowUnprocessedEmployeesWarningByOrganizationId,
} from '../../services/authService';
import { getActiveOrganizationId } from '../../services/organizationService';
import useQueueAPI from '../../hooks/useQueueAPI';
import { useTrustedPortalStore } from '../../modules/TrustedPortalModule/store';
import useMigrationAPI from '../../hooks/useMigrationAPI';

const EmployeesPage = () => {
  const { t } = useTranslations();
  const activeOrganizationId = getActiveOrganizationId();
  const [isEmployeesInviteModalOpen, setIsEmployeesInviteModalOpen] = useState(false);
  const [isEmployeesInviteLoading, setIsEmployeesInviteLoading] = useState(false);
  const [isEmployeeArchiveModalOpen, setIsEmployeeArchiveModalOpen] = useState(false);
  const [isEmployeeArchiveLoading, setIsEmployeeArchiveLoading] = useState(false);
  const [isEmployeeDeleteModalOpen, setIsEmployeeDeleteModalOpen] = useState(false);
  const [isEmployeeRestoreModalOpen, setIsEmployeeRestoreModalOpen] = useState(false);
  const [isEmployeeDeleteLoading, setIsEmployeeDeleteLoading] = useState(false);
  const [isEmployeeRestoreLoading, setIsEmployeeRestoreLoading] = useState(false);
  const [isSendInviteModalOpen, setIsSendInviteModalOpen] = useState(false);
  const [isSendInviteLoading, setIsSendInviteLoading] = useState(false);
  const [isUnprocessedEmployeesModalOpen, setIsUnprocessedEmployeesModalOpen] = useState(false);
  const [isUnprocessedEmployeesModalShown, setIsUnprocessedEmployeesModalShown] = useState(() => {
    if (!activeOrganizationId) {
      return false;
    }
    return !getShouldShowUnprocessedEmployeesWarningByOrganizationId(activeOrganizationId);
  });
  const [isEmployeeRedirectModalOpen, setIsEmployeeRedirectModalOpen] = useState(false);
  const [isEmployeeRedirectLoading, setIsEmployeeRedirectLoading] = useState(false);
  const handleEmployeesInviteModalOpen = useCallback(() => setIsEmployeesInviteModalOpen(true), []);
  const handleEmployeesInviteModalClose = useCallback(
    () => setIsEmployeesInviteModalOpen(false),
    [],
  );
  const handleEmployeeArchiveModalOpen = useCallback(() => setIsEmployeeArchiveModalOpen(true), []);
  const handleEmployeeArchiveModalClose = useCallback(
    () => setIsEmployeeArchiveModalOpen(false),
    [],
  );
  const handleEmployeeDeleteModalOpen = useCallback(() => setIsEmployeeDeleteModalOpen(true), []);
  const handleEmployeeDeleteModalClose = useCallback(() => setIsEmployeeDeleteModalOpen(false), []);
  const handleEmployeeRestoreModalOpen = useCallback(() => setIsEmployeeRestoreModalOpen(true), []);
  const handleEmployeeRestoreModalClose = useCallback(
    () => setIsEmployeeRestoreModalOpen(false),
    [],
  );
  const handleSendInviteModalOpen = useCallback(() => setIsSendInviteModalOpen(true), []);
  const handleSendInviteModalClose = useCallback(() => setIsSendInviteModalOpen(false), []);
  const handleEmployeeRedirectModalOpen = useCallback(
    () => setIsEmployeeRedirectModalOpen(true),
    [],
  );
  const handleEmployeeRedirectModalClose = useCallback(
    () => setIsEmployeeRedirectModalOpen(false),
    [],
  );

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();

  // preload for invite users modal
  useLanguages();

  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const { showSuccess, showError, showWarning } = useSnackbar();
  const {
    inviteBulk, archiveEmployeeAccounts, sendInviteBulk, restoreEmployeeAccounts,
  } = useEmployeeAccountsAPI();
  const { sendInviteBulk: sendInviteMigratedBulk } = useMigrationAPI();
  const { mutate: mutateDelete } = useDeleteEmployeeAccounts();
  const { redirectEventsToTrustedPortal } = useQueueAPI();

  const { data: organizationSecuritySettings } = useGetOrganizationSecuritySettings();
  const { trustedPortalAllowed } = organizationSecuritySettings ?? {};

  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const { data: unprocessedSCIMUsersCountResponse } = useGetUnprocessedSCIMUsersCount({
    enabled: !isUnprocessedEmployeesModalShown,
  });
  const { count: unprocessedSCIMUsersCount = 0 } = unprocessedSCIMUsersCountResponse ?? {};
  const hasUnprocessedUsersCreatedByScim = !!unprocessedSCIMUsersCount;

  const handleEmployeesInvite = useCallback(async (
    params: IInviteEmployeesParams,
  ): Promise<void> => {
    setIsEmployeesInviteLoading(true);
    try {
      const { succeededCount } = await inviteBulk(params);
      const { shouldInvite } = params;
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.eventToAddEmployeesCreated'),
        });
        if (shouldInvite) {
          showSuccess({
            text: t('trustedPortalSuccessNotifications.invitationEmailCreated'),
          });
        }
      }
      if (!isTrustedPortalEnabled) {
        if (shouldInvite) {
          showWarning({
            text: succeededCount
              ? t('inviteEmployeesModal.newEmployeesWillBeAdded', { count: succeededCount })
              : t('inviteEmployeesModal.noEmployeesWillBeAdded'),
          });
        } else {
          showWarning({
            text: succeededCount
              ? t('inviteEmployeesModal.newEmployeesWillBeStaged', { count: succeededCount })
              : t('inviteEmployeesModal.noEmployeesWillBeAdded'),
          });
        }
      }
      setIsEmployeesInviteModalOpen(false);
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeesPage/handleEmployeesInvite exception',
      });
    } finally {
      setIsEmployeesInviteLoading(false);
    }
  }, [inviteBulk, isTrustedPortalEnabled, showSuccess, showWarning, showError, t]);

  const {
    employees,
    selectedEmployees,
    refetch,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useEmployeesTable({
    noDataMessageKey: 'employeesPage.noData',
  });

  const handleRefetch = useCallback(() => refetch(true), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_EMPLOYEE, handleRefetch);

  const [isGroupsSelectorModalOpen, setIsGroupsSelectorModalOpen] = useState(false);
  const handleGroupsSelectorModalOpen = useCallback(() => setIsGroupsSelectorModalOpen(true), []);
  const handleGroupsSelectorModalClose = useCallback(() => setIsGroupsSelectorModalOpen(false), []);
  const {
    values: selectedEmployeesAsObjects, keys: selectedEmployeesIds,
  } = useMapKeyValueExtractor<IEmployeesTableRow>(selectedEmployees);

  const handleArchiveEmployee = useCallback(async () => {
    setIsEmployeeArchiveLoading(true);
    try {
      const {
        failCount,
        successCount,
        archiveBulkEmployeeAccountDetails,
      } = await archiveEmployeeAccounts({ employeeAccountIds: selectedEmployeesIds });
      if (successCount) {
        showSuccess({
          text: t('archiveEmployeeModal.successMessage', { count: successCount }),
        });
      }
      if (failCount) {
        showError({
          text: t('archiveEmployeeModal.errorMessage', { count: failCount }),
        });
      }
      const lastAdminError = archiveBulkEmployeeAccountDetails.find(
        (el) => el.errorDetails === ArchiveBulkEmployeeAccountErrorDetails.IsCurrentAdmin,
      );
      if (lastAdminError) {
        showError({
          text: t(
            'archiveEmployeeModal.cannotBeRemovedErrorMessage',
            { email: lastAdminError.email },
          ),
        });
      }
      handleEmployeeArchiveModalClose();
      resetSelectedRows();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeesPage/handleArchiveEmployee exception',
      });
    } finally {
      setIsEmployeeArchiveLoading(false);
    }
  }, [
    selectedEmployeesIds,
    archiveEmployeeAccounts,
    handleEmployeeArchiveModalClose,
    resetSelectedRows,
    showError,
    showSuccess,
    t,
  ]);

  const handleSendInvite = useCallback(async (language: string) => {
    setIsSendInviteLoading(true);
    try {
      const { stagedAndInvitedEmployeesIds, migratedEmployeesIds } = selectedEmployeesAsObjects
        .reduce<
          { stagedAndInvitedEmployeesIds: string[], migratedEmployeesIds: string[] }
        >((acc, selectedEmployee) => {
          if (selectedEmployee.employeeAccountStatus === EmployeeAccountStatus.Invited
            || selectedEmployee.employeeAccountStatus === EmployeeAccountStatus.Staged) {
            acc.stagedAndInvitedEmployeesIds.push(selectedEmployee.employeeAccountId);
          } else {
            acc.migratedEmployeesIds.push(selectedEmployee.employeeAccountId);
          }
          return acc;
        }, { stagedAndInvitedEmployeesIds: [], migratedEmployeesIds: [] });
      const [responseStagedAndInvited, responseMigrated] = await Promise.all(
        [
          stagedAndInvitedEmployeesIds.length > 0
            ? sendInviteBulk({ language, employeeAccountIds: stagedAndInvitedEmployeesIds })
            : null,
          migratedEmployeesIds.length > 0
            ? sendInviteMigratedBulk(migratedEmployeesIds)
            : null,
        ],
      );
      const succeededCount = (responseStagedAndInvited?.succeededCount || 0)
        + (responseMigrated?.successCount || 0);
      const failedCount = (responseStagedAndInvited?.failedCount || 0)
        + (responseMigrated?.failCount || 0);
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.invitationEmailCreated'),
        });
      } else {
        if (succeededCount) {
          showWarning({
            text: t('sendInviteModal.successMessage', { count: succeededCount }),
          });
        }
        if (failedCount) {
          showError({
            text: t('common.somethingWentWrong', { count: failedCount }),
          });
        }
      }
      handleSendInviteModalClose();
      resetSelectedRows();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeesPage/handleSendInvite exception',
      });
    } finally {
      setIsSendInviteLoading(false);
    }
  }, [
    selectedEmployeesAsObjects,
    sendInviteBulk,
    sendInviteMigratedBulk,
    handleSendInviteModalClose,
    resetSelectedRows,
    isTrustedPortalEnabled,
    showSuccess,
    showError,
    showWarning,
    t,
  ]);

  const handleDeleteEmployee = useCallback(async () => {
    setIsEmployeeDeleteLoading(true);
    mutateDelete({ employeeAccountIds: selectedEmployeesIds }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteEmployeeModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteEmployeeModal.errorMessage', { count: failCount }),
          });
        }
        handleEmployeeDeleteModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSettled: () => setIsEmployeeDeleteLoading(false),
    });
  }, [
    selectedEmployeesIds,
    mutateDelete,
    handleEmployeeDeleteModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleRestoreEmployee = useCallback(async () => {
    setIsEmployeeRestoreLoading(true);
    try {
      const {
        failCount, successCount,
      } = await restoreEmployeeAccounts({ employeeAccountIds: selectedEmployeesIds });
      if (successCount) {
        showSuccess({
          text: t('restoreEmployeeModal.successMessage', { count: successCount }),
        });
      }
      if (failCount) {
        showError({
          text: t('restoreEmployeeModal.errorMessage', { count: failCount }),
        });
      }
      handleEmployeeRestoreModalClose();
      resetSelectedRows();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeesPage/handleRestoreEmployee exception',
      });
    } finally {
      setIsEmployeeRestoreLoading(false);
    }
  }, [
    selectedEmployeesIds,
    restoreEmployeeAccounts,
    handleEmployeeRestoreModalClose,
    resetSelectedRows,
    showError,
    showSuccess,
    t,
  ]);

  const handleRedirectEmployee = useCallback(async () => {
    setIsEmployeeRedirectLoading(true);
    try {
      await redirectEventsToTrustedPortal({ employeeAccountIds: selectedEmployeesIds });
      handleEmployeeRedirectModalClose();
      resetSelectedRows();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeesPage/handleRedirectEmployee exception',
      });
    } finally {
      setIsEmployeeRedirectLoading(false);
    }
  }, [
    selectedEmployeesIds,
    redirectEventsToTrustedPortal,
    handleEmployeeRedirectModalClose,
    resetSelectedRows,
    showError,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((updatedValues: IEmployeesListFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const {
    isAddToGroupDisabled,
    isSendInviteDisabled,
    isArchiveDisabled,
    isDeleteDisabled,
    isRestoreDisabled,
    isRedirectDisabled,
  } = useMemo(() => {
    if (!selectedEmployeesAsObjects.length) {
      return {
        isAddToGroupDisabled: true,
        isSendInviteDisabled: true,
        isArchiveDisabled: true,
        isDeleteDisabled: true,
        isRestoreDisabled: true,
        isRedirectDisabled: true,
      };
    }
    let disableAddToGroup = false;
    let disableSendInvite = false;
    let disableArchive = false;
    let disableDelete = false;
    let disableRestore = false;
    let disableRedirect = false;
    if (!isTrustedPortalEnabled) {
      disableRedirect = true;
    }
    selectedEmployeesAsObjects.forEach(({ employeeAccountStatus, isByScim }) => {
      if (employeeAccountStatus === EmployeeAccountStatus.Archived) {
        if (isByScim) {
          disableRestore = true;
        }
        disableAddToGroup = true;
        disableArchive = true;
        disableSendInvite = true;
        disableRedirect = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Active) {
        if (isByScim) {
          disableArchive = true;
        }
        disableSendInvite = true;
        disableDelete = true;
        disableRestore = true;
        disableRedirect = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Invited) {
        if (isByScim) {
          disableDelete = true;
        }
        disableArchive = true;
        disableRestore = true;
        disableRedirect = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Staged) {
        disableArchive = true;
        disableRestore = true;
        disableRedirect = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Unprocessed) {
        disableAddToGroup = true;
        disableArchive = true;
        disableSendInvite = true;
        disableRestore = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.ExistingUnprocessed) {
        disableAddToGroup = true;
        disableArchive = true;
        disableSendInvite = true;
        disableRestore = true;
        disableRedirect = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Migrated) {
        disableArchive = true;
        disableRestore = true;
        disableRedirect = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.MigrationInvited) {
        disableArchive = true;
        disableSendInvite = true;
        disableRestore = true;
        disableRedirect = true;
      }
    });
    return {
      isAddToGroupDisabled: disableAddToGroup,
      isSendInviteDisabled: disableSendInvite,
      isArchiveDisabled: disableArchive,
      isDeleteDisabled: disableDelete,
      isRestoreDisabled: disableRestore,
      isRedirectDisabled: disableRedirect,
    };
  }, [selectedEmployeesAsObjects, isTrustedPortalEnabled]);

  const {
    addToGroupTooltipTitle,
    sendInviteTooltipTitle,
    archiveTooltipTitle,
    deleteTooltipTitle,
    restoreTooltipTitle,
    redirectTooltipTitle,
  } = useMemo(() => {
    const addToGroupTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isAddToGroupDisabled,
      key: 'employeesPage.addToGroup',
    });
    const sendInviteTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isSendInviteDisabled,
      key: 'employeesPage.sendInvite',
    });
    const archiveTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isArchiveDisabled,
      key: 'employeesPage.archive',
    });
    const deleteTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isDeleteDisabled,
      key: 'employeesPage.delete',
    });
    const restoreTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isRestoreDisabled,
      key: 'employeesPage.restore',
    });
    const redirectTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isRedirectDisabled,
      isTrustedPortalDisabled: !isTrustedPortalEnabled,
      key: 'employeesPage.redirect',
    });

    return {
      addToGroupTooltipTitle: addToGroupTitle,
      sendInviteTooltipTitle: sendInviteTitle,
      archiveTooltipTitle: archiveTitle,
      deleteTooltipTitle: deleteTitle,
      restoreTooltipTitle: restoreTitle,
      redirectTooltipTitle: redirectTitle,
    };
  }, [
    selectedEmployeesAsObjects,
    t,
    isAddToGroupDisabled,
    isSendInviteDisabled,
    isRedirectDisabled,
    isTrustedPortalEnabled,
    isArchiveDisabled,
    isDeleteDisabled,
    isRestoreDisabled,
  ]);

  const handleUnprocessedEmployeesModalClose = useCallback((
    isDoNotShowCheckboxChecked: boolean,
  ) => {
    if (!activeOrganizationId) {
      return;
    }
    if (isDoNotShowCheckboxChecked) {
      setShouldShowUnprocessedEmployeesWarningByOrganizationId(activeOrganizationId, false);
    }
    setIsUnprocessedEmployeesModalShown(true);
    setIsUnprocessedEmployeesModalOpen(false);
  }, [activeOrganizationId]);

  useEffect(() => {
    if (!hasUnprocessedUsersCreatedByScim) {
      return;
    }
    if (isUnprocessedEmployeesModalShown) {
      return;
    }
    setIsUnprocessedEmployeesModalOpen(true);
  }, [hasUnprocessedUsersCreatedByScim, isUnprocessedEmployeesModalShown]);

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={addToGroupTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleGroupsSelectorModalOpen}
                      disabled={isAddToGroupDisabled}
                    >
                      <GroupIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={sendInviteTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleSendInviteModalOpen}
                      disabled={isSendInviteDisabled}
                    >
                      <MessageIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={archiveTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleEmployeeArchiveModalOpen}
                      disabled={isArchiveDisabled}
                    >
                      <ArchiveIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={deleteTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleEmployeeDeleteModalOpen}
                      disabled={isDeleteDisabled}
                    >
                      <DeleteIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={restoreTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleEmployeeRestoreModalOpen}
                      disabled={isRestoreDisabled}
                    >
                      <RecoverIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                {trustedPortalAllowed && (
                  <Grid item alignSelf="center">
                    <Tooltip title={redirectTooltipTitle}>
                      <ActionButton
                        width={40}
                        height={40}
                        onClick={handleEmployeeRedirectModalOpen}
                        disabled={isRedirectDisabled}
                      >
                        <RedirectIcon />
                      </ActionButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
              </Grid>
              <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <Box mr={3} />
                <Grid item>
                  <Button
                    icon={<InviteUserIcon />}
                    onClick={handleEmployeesInviteModalOpen}
                  >
                    {t('employeesPage.inviteEmployeesButton')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          <EmployeesTable
            employees={employees}
            selectedEmployees={selectedEmployees}
            {...restTableProps}
          />
        </Panel>
      </Grid>
      {isEmployeesInviteModalOpen && (
        <InviteEmployeesModal
          isOpen={isEmployeesInviteModalOpen}
          onEmployeesInvite={handleEmployeesInvite}
          onClose={handleEmployeesInviteModalClose}
          isLoading={isEmployeesInviteLoading}
        />
      )}
      <EmployeesListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isGroupsSelectorModalOpen && (
        <GroupsSelectorModal
          isOpen={isGroupsSelectorModalOpen}
          onClose={handleGroupsSelectorModalClose}
          employeeAccountsIds={selectedEmployeesIds}
        />
      )}
      {isEmployeeArchiveModalOpen && (
        <PromptModal
          open={isEmployeeArchiveModalOpen}
          onClose={handleEmployeeArchiveModalClose}
          onSubmit={handleArchiveEmployee}
          title={t('archiveEmployeeModal.title', { count: selectedEmployees.size })}
          description={t('archiveEmployeeModal.description')}
          list={selectedEmployeesAsObjects}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isEmployeeArchiveLoading}
          approvalButtonText="common.archive"
        />
      )}
      {isEmployeeDeleteModalOpen && (
        <PromptModal
          open={isEmployeeDeleteModalOpen}
          onClose={handleEmployeeDeleteModalClose}
          onSubmit={handleDeleteEmployee}
          title={t('deleteEmployeeModal.title', { count: selectedEmployees.size })}
          description={t('deleteEmployeeModal.description')}
          list={selectedEmployeesAsObjects}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isEmployeeDeleteLoading}
          approvalButtonText="common.delete"
        />
      )}
      {isEmployeeRestoreModalOpen && (
        <PromptModal
          open={isEmployeeRestoreModalOpen}
          onClose={handleEmployeeRestoreModalClose}
          onSubmit={handleRestoreEmployee}
          title={t('restoreEmployeeModal.title', { count: selectedEmployees.size })}
          description={t('restoreEmployeeModal.description')}
          list={selectedEmployeesAsObjects}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isEmployeeRestoreLoading}
          approvalButtonText="common.restore"
          approvalButtonColor="primary"
        />
      )}
      {isSendInviteModalOpen && (
        <SendInviteModal
          isOpen={isSendInviteModalOpen}
          onClose={handleSendInviteModalClose}
          onApprove={handleSendInvite}
          isLoading={isSendInviteLoading}
        />
      )}
      {isUnprocessedEmployeesModalOpen && (
        <UnprocessedEmployeesModal
          isOpen={isUnprocessedEmployeesModalOpen}
          onClose={handleUnprocessedEmployeesModalClose}
        />
      )}
      {isEmployeeRedirectModalOpen && (
        <PromptModal
          open={isEmployeeRedirectModalOpen}
          onClose={handleEmployeeRedirectModalClose}
          onSubmit={handleRedirectEmployee}
          title={t('redirectEmployeeModal.title')}
          description={t('redirectEmployeeModal.description')}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isEmployeeRedirectLoading}
          approvalButtonText="redirectEmployeeModal.redirect"
          approvalButtonColor="primary"
        />
      )}
    </Grid>
  );
};

export default EmployeesPage;
