import { useMemo, memo } from 'react';
import {
  Typography, Dialog, DialogTitle, useTranslations, Table, useTable,
} from '@uniqkey-frontend/shared-app';
import { CheckResponseModel } from '@uniqkey-backend-organization-web/api-client';
import { noop } from 'lodash';

interface IViewEmployeesModalProps {
  handleToggle: () => void;
  isModalOpen: boolean;
  employees: CheckResponseModel[];
}

const ViewEmployeesModal = (props: IViewEmployeesModalProps) => {
  const { handleToggle, isModalOpen, employees } = props;
  const { t } = useTranslations();
  const { createColumns } = useTable<CheckResponseModel>();

  const columns = useMemo(() => createColumns([
    {
      title: t('migration.viewEmployeesModal.email'),
      field: 'email',
      render: (rowData) => (
        <Typography variant="body2">{rowData.email}</Typography>
      ),
      width: '50%',
      sorting: false,
    },
    {
      title: t('migration.viewEmployeesModal.name'),
      field: 'name',
      render: (rowData) => (
        <Typography variant="body2">{rowData.name}</Typography>
      ),
      width: '50%',
      sorting: false,
    },
  ]), [createColumns, t]);

  const employeesCount = useMemo(() => employees.length, [employees]);

  return (
    <Dialog onClose={handleToggle} open={isModalOpen} maxWidth="xl">
      <DialogTitle onClose={handleToggle}>
        {t('migration.viewEmployeesModal.title', { count: employeesCount })}
      </DialogTitle>
      <Table
        t={t}
        data={employees}
        activePage={1}
        columns={columns}
        onActivePageChange={noop}
        onPerPageChange={noop}
        perPage={employeesCount}
        totalItems={employeesCount}
        rowHeight={51}
        hidePagination
      />
    </Dialog>
  );
};

export default memo(ViewEmployeesModal);
