import { memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTranslations,
  Grid,
  Button,
  DialogActions,
  emptyStringValidator,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';

export interface IEditEmployeeModalReturnValue {
  name: string;
}

interface IEditEmployeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: IEditEmployeeModalReturnValue) => Promise<void> | void;
  name: string;
  isLoading: boolean;
}

const EditEmployeeModal = (props: IEditEmployeeModalProps) => {
  const {
    isOpen, onClose, onSubmit, name, isLoading,
  } = props;
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IEditEmployeeModalReturnValue>(
    { defaultValues: { name } },
  );

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('editEmployeeModal.title')}
        </DialogTitle>
        <DialogContent>
          <ReactHookFormTextField
            name="name"
            control={control}
            fullWidth
            autoFocus
            error={!!errors.name}
            label={t('editEmployeeModal.employeeName.label')}
            placeholder={t('editEmployeeModal.employeeName.placeholder')}
            helperText={errors.name?.message}
            rules={{
              required: t('validation.required'),
              validate: (value) => (
                emptyStringValidator(value) ? t('validation.required') : true
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth disabled={!isDirty} isLoading={isLoading} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditEmployeeModal);
