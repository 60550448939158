import { memo, useMemo } from 'react';
import type { ChartOptions, Scale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { merge } from 'lodash';
import {
  Grid,
  Typography,
  S1,
  S5,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import useLineChart from '../../../hooks/useLineChart';
import type { TLineChartType, IChartDataPoint } from '../../../helpers/ChartJSLib/interfaces';

interface ILineChartProps {
  data: IChartDataPoint[];
  options?: ChartOptions<TLineChartType>;
  backgroundColor?: string;
  withGradient?: boolean;
  noDataTranslationKey?: string;
}

const DATE_FORMAT = 'd LLL';

const Y_AXIS_TICKS_AS_SET = new Set([10, 25, 50, 75, 100]);

const DEFAULT_OPTIONS: ChartOptions<TLineChartType> = {
  interaction: {
    axis: 'x',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: ([tooltipItem]) => `${tooltipItem.formattedValue}%`,
        label: (tooltipItem) => tooltipItem.label,
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: DATE_FORMAT,
        },
        tooltipFormat: DATE_FORMAT,
      },
      grid: {
        drawTicks: false,
      },
      ticks: {
        source: 'data',
      },
      border: {
        // hide the X-axis by making it transparent
        color: 'transparent',
      },
    },
    y: {
      type: 'linear',
      min: 0,
      max: 100,
      grid: {
        drawTicks: false,
      },
      afterBuildTicks: (axis: Scale) => {
        // eslint-disable-next-line no-param-reassign
        axis.ticks = axis.ticks.filter((tick) => Y_AXIS_TICKS_AS_SET.has(tick.value));
      },
      ticks: {
        callback: (value) => `${value}%`,
        stepSize: 5,
        align: 'inner',
      },
    },
  },
};

const TimeToPercentLineChart = (props: ILineChartProps) => {
  const {
    data,
    options,
    backgroundColor = S1,
    withGradient,
    noDataTranslationKey,
  } = props;

  const isWithOnePointOnly = data.length === 1;

  const { t, currentLanguage } = useTranslations();

  const { initialChartData, chartRef } = useLineChart({
    data,
    parsing: false, // chart will get parsed records with { x, y }
    withGradient: withGradient ?? !isWithOnePointOnly,
  });

  const processedOptions = useMemo(() => merge(
    {},
    DEFAULT_OPTIONS,
    {
      locale: currentLanguage,
      scales: {
        x: {
          ticks: {
            align: isWithOnePointOnly ? 'center' : 'end',
          },
        },
      },
    },
    options,
  ), [currentLanguage, options, isWithOnePointOnly]);

  if (!data.length) {
    if (!noDataTranslationKey) {
      return null;
    }
    return (
      <Grid container alignItems="center" justifyContent="center" sx={{ backgroundColor }}>
        <Typography variant="body1" color={S5}>
          {t(noDataTranslationKey)}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid
      sx={{
        backgroundColor,
        position: 'relative',
        width: '100%',
        '& .chart': {
          width: '100% !important',
          height: '100% !important',
        },
      }}
    >
      <Line
        className="chart"
        ref={chartRef}
        options={processedOptions}
        data={initialChartData}
      />
    </Grid>
  );
};

export default memo(TimeToPercentLineChart);
