import { useCallback, memo } from 'react';
import {
  useTranslations,
  validateEmail,
  Grid,
  Typography,
  Button,
  S5,
  PlusIcon,
  Box,
  generateGUID,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';
import { Message, useForm } from 'react-hook-form';
import { IInviteEmployee, IUserFromCSV } from '../../interfaces';

interface IInviteEmployeeProps {
  onSubmit: (newUser: IInviteEmployee) => void;
}

const InviteEmployeeForm = (props: IInviteEmployeeProps) => {
  const { onSubmit } = props;
  const { t } = useTranslations();
  const {
    control, handleSubmit, formState, reset,
  } = useForm<IUserFromCSV>({
    mode: 'all',
    defaultValues: {
      email: '',
      name: '',
    },
  });
  const handleFormSubmit = useCallback((value: IUserFromCSV) => {
    onSubmit({ ...value, id: generateGUID() });
    reset();
  }, [onSubmit, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
      <Typography
        variant="subtitle1"
        color={S5}
      >
        {t('inviteEmployeesModal.addManually')}
      </Typography>
      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReactHookFormTextField
            name="email"
            control={control}
            autoFocus
            error={!!formState?.errors?.email}
            helperText={formState?.errors?.email?.message}
            rules={{
              validate: (email): Message | boolean => {
                if (!email) {
                  return true;
                }
                const isValid = validateEmail(email);
                if (!isValid) {
                  return t('validation.invalidEmail') as string;
                }
                return true;
              },
            }}
            required
            label={t('common.email.label')}
            placeholder={t('common.email.placeholder')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <ReactHookFormTextField
            name="name"
            control={control}
            error={!!formState?.errors?.name}
            helperText={formState?.errors?.name?.message}
            label={t('common.name.label')}
            placeholder={t('common.name.placeholder')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            icon={<PlusIcon />}
            disabled={!formState.dirtyFields.email}
            type="submit"
            fullWidth
          >
            {t('inviteEmployeesModal.addUser')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default memo(InviteEmployeeForm);
