import { memo, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  ReactHookFormAutocomplete,
  Button,
  useTranslations,
  getCountriesList,
  getCitiesByCountryName,
  validateEmail,
  validateAddress,
  validateVAT,
  validatePhone,
  validateZIPCode,
  maxLengthValidator,
  FormHiddenInput,
  emptyStringValidator,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { GetCurrentOrganizationResponse } from '@uniqkey-backend-organization-web/api-client';

interface IEditOrganizationDetailsModalFormValue {
  name: GetCurrentOrganizationResponse['name'];
  country: GetCurrentOrganizationResponse['country'] | null;
  billingEmail: GetCurrentOrganizationResponse['billingEmail'];
  city: GetCurrentOrganizationResponse['city'] | null;
  vat: GetCurrentOrganizationResponse['vat'];
  address: GetCurrentOrganizationResponse['address'];
  phone: GetCurrentOrganizationResponse['phone'];
  zip: GetCurrentOrganizationResponse['zip'];
}

export type TEditOrganizationDetailsModalReturn = {
  key: keyof IEditOrganizationDetailsModalFormValue;
  value: string;
}[];

interface IEditOrganizationDetailsModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: TEditOrganizationDetailsModalReturn) => Promise<void> | void;
  onClose: () => void;
  organization: IEditOrganizationDetailsModalFormValue;
}

const EditOrganizationDetailsModal = (props: IEditOrganizationDetailsModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose, organization,
  } = props;

  const { t } = useTranslations();

  const {
    name, country, billingEmail, city, vat, address, phone, zip,
  } = organization;

  const [countriesOptions, countriesAsObject] = useMemo(() => {
    const options = getCountriesList();
    const optionsAsObject = options.reduce((acc, option) => ({
      ...acc,
      [option]: option,
    }), {});
    return [options, optionsAsObject];
  }, []);

  const {
    handleSubmit,
    formState: { isDirty, dirtyFields, errors },
    control,
    setValue,
    watch,
  } = useForm<IEditOrganizationDetailsModalFormValue>({
    defaultValues: {
      name,
      // @ts-ignore TODO: remove ts-ignore
      country: country && countriesAsObject[country] ? country : null,
      billingEmail,
      // @ts-ignore TODO: remove ts-ignore
      city: country && countriesAsObject[country] ? city : null,
      vat,
      address,
      phone,
      zip,
    },
  });

  const selectedCountry = watch('country');

  const citiesOptions = useMemo(() => (selectedCountry
    ? getCitiesByCountryName(selectedCountry)
    : []
  ), [selectedCountry]);

  const handleFormSubmit = useCallback((values: IEditOrganizationDetailsModalFormValue) => {
    const changedFieldsNames = Object.keys(dirtyFields);
    const changes = changedFieldsNames.map((changedFieldName) => ({
      key: changedFieldName,
      value: values[changedFieldName as keyof IEditOrganizationDetailsModalFormValue],
    }));
    onSubmit(changes as TEditOrganizationDetailsModalReturn);
  }, [dirtyFields, onSubmit]);

  const handleCountryChange = useCallback(() => {
    setValue('city', null);
  }, [setValue]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('editOrganizationModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={4} columnSpacing={2}>
            <Grid item xs={6}>
              <FormHiddenInput />
              <ReactHookFormTextField
                name="name"
                control={control}
                fullWidth
                autoFocus
                autoComplete="none"
                label={`${t('editOrganizationModal.organizationName.label')}*`}
                placeholder={t('editOrganizationModal.organizationName.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="country"
                onChange={handleCountryChange}
                options={countriesOptions}
                control={control}
                label={`${t('editOrganizationModal.country.label')}*`}
                placeholder={t('editOrganizationModal.country.placeholder')}
                error={!!errors.country}
                helperText={errors.country?.message}
                disableAutoFill
                rules={{ required: t('validation.required') }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="billingEmail"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editOrganizationModal.billingEmail.label')}*`}
                placeholder={t('editOrganizationModal.billingEmail.placeholder')}
                error={!!errors.billingEmail}
                helperText={errors.billingEmail?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (value) => validateEmail(value as string)
                      || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="city"
                freeSolo
                options={citiesOptions}
                control={control}
                label={`${t('editOrganizationModal.city.label')}*`}
                placeholder={t('editOrganizationModal.city.placeholder')}
                error={!!errors.city}
                helperText={errors.city?.message}
                disableAutoFill
                disabled={!selectedCountry}
                rules={{
                  validate: {
                    isValid: (v) => validateAddress(v as string) || t('validation.invalidCity'),
                    required: (v) => (selectedCountry && !v ? t('validation.required') : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="vat"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editOrganizationModal.vat.label')}*`}
                placeholder={t('editOrganizationModal.vat.placeholder')}
                error={!!errors.vat}
                helperText={errors.vat?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateVAT(v as string)
                        || t('validation.invalidVAT'),
                    maxLength: (v) => maxLengthValidator(20, v as string)
                        || t('validation.maxLength', { length: 20 }),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="address"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editOrganizationModal.address.label')}*`}
                placeholder={t('editOrganizationModal.address.placeholder')}
                error={!!errors.address}
                helperText={errors.address?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateAddress(v) || t('validation.invalidAddress'),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="phone"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editOrganizationModal.phone.label')}*`}
                placeholder={t('editOrganizationModal.phone.placeholder')}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validatePhone(v as string)
                        || t('validation.invalidPhone'),
                    maxLength: (v) => maxLengthValidator(19, v as string)
                        || t('validation.maxLength', { length: 19 }),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="zip"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editOrganizationModal.zipCode.label')}*`}
                placeholder={t('editOrganizationModal.zipCode.placeholder')}
                error={!!errors.zip}
                helperText={errors.zip?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateZIPCode(v as string)
                        || t('validation.invalidZIPCode'),
                    maxLength: (v) => maxLengthValidator(12, v as string)
                        || t('validation.maxLength', { length: 12 }),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            <Grid item xs />
            <Grid item xs={2.5}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditOrganizationDetailsModal);
